import { ComponentScopes } from 'piivo-poster-engine/src/constants/scopes';
import { PosterEngineExtensionPoint } from 'piivo-poster-engine/src/extensionPoints';
import { getComponentsManager } from 'piivo-poster-engine/src/services/componentsManager';
import { getFontsManager } from 'piivo-poster-engine/src/services/fontsManager';
import { PosterPaginationContext } from 'piivo-poster-engine/src/types/rendering';
import Vue from 'vue';
import { Store } from 'vuex';

import { getExtensionPoint } from '../../../core/extensionPoints';
import { isoDateFull } from '../../../core/filters';
import i18n from '../../../core/i18n';
import router from '../../../core/router';
import services from '../../../core/services';
import { IAuthenticationService, ILanguagesManagerService } from '../../../core/services/types';
import { USER_LOGOUT } from '../../../core/store/modules/coreModule';
import { Label } from '../../../core/types/i18n';
import { IAlertsService } from '../../../platform/services/types';
import { AttributeValues, Form } from '../../common/api/attributes';
import { AttributeTypes, DisplayMode } from '../../common/constants';
import { areAllAttributesValid } from '../../common/helpers/attributeValidators';
import {
  getNormalizedValue,
  iterateForm,
  queryToFormValues,
} from '../../common/helpers/formsHelper';
import { IFormsService } from '../../common/services/types';
import logs from '../api/logs';
import { PosterLog } from '../api/logsTypes';
import { PosterForm, TemplateFormats, TemplateTypes, TemplateValues } from '../api/types';
import { AttributeAlias, DEFAULT_SIGNAGE_SOURCE, PosterMimeTypes, Statutes } from '../constants';
import { AttributeCartSummaryTag } from '../constants/cart';
import { NAMESPACE as POSTER_NAMESPACE, RESET_STATE } from '../store/modules/poster';
import { SearchAttributeMapping, SearchModeSearchConfiguration } from '../types/import';
import { AttributeSummary, CartPoster, FormatDetails, FormatsCopiesMap } from '../types/poster';
import { getPosterService } from '.';
import { EditablePage, EditableTemplate, SignageValues } from './../types/templates';
import { TypeData, TypeFormData } from './templatesService';
import {
  IGlobalAttributesService,
  IPosterResourcesService,
  ISignagesService,
  ITemplatesService,
  SearchResultUpdate,
} from './types';

let signageId = -1;

/**
 * Separator for inputs
 */
const SUMMARY_SEPARATOR = ', ';

/**
 * Gets the normalized value so that it can be used
 * in a renderer
 *
 * @param valueObj - attribute value object
 * @returns attribute value
 */
function getNormalizedRendererValue(
  valueObj: AttributeValues.ValueObject
): AttributeValues.AttributeValue {
  let parsed = valueObj.value;

  if (typeof parsed === 'string') {
    parsed = parsed.replace(/\\n/g, '\n');
  } else if (valueObj.type === AttributeTypes.DATE) {
    parsed = isoDateFull(parsed as unknown as Date);
  } else if (valueObj.type === AttributeTypes.LINKS && Array.isArray(parsed) && parsed.length) {
    // Array-like value (possibly table)
    parsed = (parsed as unknown[]).map((line) => {
      // Return simple value
      if (!Array.isArray(line)) {
        return line;
      }

      // Recursively normalize values inside arrays (possibly sub Links)
      return line.map((colValue) => {
        const colAttrValue = {
          attributeAlias: null,
          attributeId: null,
          type: null,
          value: colValue,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return getNormalizedRendererValue(colAttrValue as any);
      });
    }) as any;
  }

  return parsed;
}

export class SignagesService implements ISignagesService {
  /**
   * @param store - vuex store
   */
  constructor(private readonly store: Store<unknown>) {
    store.subscribeAction((action) => {
      if (action.type === USER_LOGOUT) {
        this.clearCache();
      }
    });
  }

  async getAttributeValues(alias: string) {
    const results: any = await Vue.http.get(
      `/api/signages/attributes/${alias}/values?lang=${
        services.getService<ILanguagesManagerService>('languages').getSelectedLocale() ?? ''
      }&sorting=${encodeURIComponent('[{"priority":"asc"},{"label":"asc"}]')}`
    );

    return results.body as unknown;
  }

  /**
   * Gets signages synthesis.
   * @param storeAlias - Store alias
   * @param departmentsAlias - Departments alias
   */
  async getPendingSignagesSynthesis(storeAlias: string, departmentsAlias: string[]) {
    let request = `/api/signages/logs/fastsynthesis`;
    let didAddFirstParam = false;

    // Add store param
    if (storeAlias) {
      request += `${didAddFirstParam ? '&' : '?'}signages_signages_inputs_store=${storeAlias}`;
      didAddFirstParam = true;
    }

    // Add departments param
    if (departmentsAlias != null && departmentsAlias.length > 0) {
      request += `${didAddFirstParam ? '&' : '?'}departments=[${departmentsAlias.join(',')}]`;
      didAddFirstParam = true;
    }

    const results: any = await Vue.http.get(request);
    return results.body as unknown;
  }

  /**
   * Gets signages list for the options in parameter (Signages search).
   * @param options - Search options (paging, sorting, filtering options)
   */
  listSignages(options: {
    index?: number;
    size?: number;
    sortBy?: string[];
    filterBy?: {
      [x: string]:
        | {
            operator: 'between';
            value: {
              value: [string, string];
            };
          }
        | {
            operator: string;
            value: { itemId: string }[] | string;
          };
    };
  }) {
    const parameters = [
      `lang=${
        services.getService<ILanguagesManagerService>('languages').getSelectedLocale() ?? ''
      }`,
    ];
    if (options) {
      // Paging
      if (
        options.index !== null &&
        typeof options.index !== 'undefined' &&
        options.size !== null &&
        typeof options.size !== 'undefined'
      ) {
        parameters.push(
          `paging=${encodeURIComponent(`{"size":${options.size},"index":${options.index}}`)}`
        );
      }

      // Sort
      if (Array.isArray(options.sortBy)) {
        const sorts: string[] = [];
        for (const [sortKey, sortDirection] of options.sortBy) {
          if (sortKey && sortDirection !== 'none') {
            // Add sort options
            sorts.push(`{"${sortKey}":"${sortDirection}"}`);
          }
        }

        if (sorts.length > 0) {
          parameters.push(`sorting=${encodeURIComponent(`[${sorts.join(',')}]`)}`);
        }
      }

      // Filters
      if (options.filterBy) {
        const filters: string[] = [];
        for (const filterKey in options.filterBy) {
          if (Object.hasOwnProperty.call(options.filterBy, filterKey)) {
            const filter = options.filterBy[filterKey];
            if (filter.value !== null) {
              if (filter.operator !== 'between') {
                if (Array.isArray(filter.value) && filter.value.length > 0) {
                  // Array of values
                  const orFilters: string[] = [];
                  for (const orFilter of filter.value) {
                    orFilters.push(
                      `{"operator":"${filter.operator}","attribute":"${filterKey}","value":"${orFilter.itemId}"}`
                    );
                  }
                  filters.push(`{"operator":"or","filters":[${orFilters.join(',')}]}`);
                } else if (
                  (typeof filter.value === 'string' && filter.value.length > 0) ||
                  typeof filter.value === 'boolean'
                ) {
                  // String or boolean value
                  filters.push(
                    `{"operator":"${filter.operator}","attribute":"${filterKey}","value":"${filter.value}"}`
                  );
                }
              } else if (Array.isArray(filter.value) && filter.value.length > 1) {
                // Between filter
                const value = filter.value as unknown as string[];
                filters.push(
                  `{"operator":"${filter.operator}","attribute":"${filterKey}","values":["${value[0]}","${value[1]}"]}`
                );
              }
            }
          }
        }

        // Build 'and' request with all filters
        if (filters.length > 0) {
          parameters.push(
            `filtering=${encodeURIComponent(`{"operator":"and","filters":[${filters.join(',')}]}`)}`
          );
        }
      }
    }

    let request = '';
    if (parameters.length > 0) {
      request = `?${parameters.join('&')}`;
    }

    return Vue.http.get(`/api/signages/logs/search${request}`);
  }

  /**
   * Loads signages by signage Ids.
   * @param signageIds - Signage Ids
   * @param forEdition - Flag to indicate if signage loading is for edition purpose
   */
  async loadSignages(signageIds: string[], forEdition: boolean): Promise<EditableTemplate[]> {
    const { body: posters } = await logs.getSignagesLogs(signageIds, forEdition);

    return this.convertPosterLogsToTemplate(posters);
  }

  async convertPosterLogsToTemplate(
    signages: PosterLog.PosterLog[] | PosterLog.EditablePosterLog[]
  ): Promise<EditableTemplate[]> {
    const templatesPromises: (Promise<EditableTemplate> | EditableTemplate)[] = [];
    const templateResourceUrl =
      getPosterService<IPosterResourcesService>('posterResourcesService').getTemplateResourceUrl();

    for (const signage of signages) {
      const editableSignage: Omit<EditableTemplate, 'resolvedTemplate' | 'computedTemplate'> = {
        // Signage content
        templateId: signage.templateId,
        fonts: signage.content?.fonts ?? [],
        // Page is missing 'id' because it will be generated later
        pages: (signage.content?.pages as EditablePage[]) ?? [],

        // Signage
        itemId: signage.itemId,
        type: signage.type,
        format: signage.format,
        copies: signage.copies,
        comment: signage.comment,
        deadlineDate: Vue.filter('isoDateFull')(new Date(signage.deadlineDate)) as string,
        form: Object.hasOwnProperty.call(signage, 'form')
          ? (signage as PosterLog.EditablePosterLog).form
          : null,
        paperType: signage.paperType,
        isTainted: signage.isTainted,
        isArchived: signage.isArchived,
        mimeType: signage.mimeType,

        // Editing
        bindings: {},
        types: {},
      };

      if (editableSignage.form) {
        iterateForm(editableSignage.form, (attribute, isInput) => {
          // Legacy compat: add search onAttributeResult if missing
          getPosterService<ITemplatesService>('templates').initCompatSearchResultConfig(
            attribute,
            isInput
          );
        });
      }

      if (signage.values && editableSignage.form) {
        for (const value of signage.values) {
          const normalizedValue = getNormalizedRendererValue(value);

          if (value.attributeAlias !== null) {
            editableSignage.bindings[value.attributeAlias] = normalizedValue;
            editableSignage.types[value.attributeAlias] = value.type;
          }
        }
      }
      if (signage.mimeType === PosterMimeTypes.Poster) {
        templatesPromises.push(
          getComponentsManager().parseTemplateComponents(editableSignage, {
            template: editableSignage as any,
            data: editableSignage.bindings,
            types: editableSignage.types,
            scope: ComponentScopes.Preview,
            pagination: {
              pageIndex: 0,
              pageNumber: 0,
              pageCount: 0,
            },
            templateUrl: templateResourceUrl,
          }) as unknown as Promise<EditableTemplate>
        );
      } else {
        // Non "Poster" don't have 'computedTemplate'
        templatesPromises.push(editableSignage as EditableTemplate);
      }
    }

    const templates = await Promise.all(templatesPromises);

    try {
      await getFontsManager().registerAllTemplatesFontsIfEnabled(
        templates.map((t) => t.resolvedTemplate)
      );
    } catch (error) {
      // eslint-disable-next-line no-throw-literal
      throw { fontsError: true, error };
    }

    return templates;
  }

  /**
   * Gets signage details with a signage Id.
   *
   * @param signageId - Signage Id
   */
  async getSignageDetails(signageId: string) {
    const currentLanguage = services
      .getService<ILanguagesManagerService>('languages')
      ?.getSelectedLocale();

    try {
      const res: any = await logs.getLogDetails(signageId, currentLanguage ?? '');
      return res.body as unknown;
    } catch (error) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.signage.error.retrieve_info') as string);

      throw error;
    }
  }

  /**
   * Deletes all the signages in parameter.
   *
   * @param signages - List of signages to delete
   */
  async deleteSignages(signages: { itemId: string }[]) {
    const toDelete = signages.map((signage) => signage.itemId);

    try {
      const res: any = await logs.deleteLogs(toDelete);
      return res as unknown;
    } catch (err) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.signage.error.deletion') as string);

      throw err;
    }
  }

  /**
   * Archives all the signages in parameter.
   *
   * @param signages - List of signages to archive
   */
  async archiveSignages(signages: { itemId: string }[]) {
    const toArchive = signages.map((signage) => signage.itemId);

    try {
      const res: any = await logs.archiveLogs(toArchive);
      return res as unknown;
    } catch (err) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.signage.error.archival') as string);

      throw err;
    }
  }

  /**
   * Get logs table definition.
   */
  async getLogsTableDefinition() {
    try {
      const res: any = await logs.getLogsTableDefinition();
      return res.body as unknown;
    } catch (err) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.signage.error.retrieve_history_config') as string);

      throw err;
    }
  }

  /**
   * Get logs filters definition.
   */
  async getLogsFiltersDefinition() {
    try {
      const res: any = await logs.getLogsFiltersDefinition();
      return res.body as unknown;
    } catch (err) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.signage.error.retrieve_filters') as string);

      throw err;
    }
  }

  /**
   * Get logs details definition.
   */
  async getLogsDetailsDefinition() {
    try {
      const res: any = await logs.getLogsDetailsDefinition();
      return res.body as unknown;
    } catch (err) {
      // Alert error
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.signage.error.retrieve_data') as string);

      throw err;
    }
  }

  /**
   * Checks if the signage has been modified.
   * @param {Object} form - Form object
   * @param taintedValues - signage tainted values
   */
  isSignageTainted(form: PosterForm, taintedValues: { [alias: string]: boolean }): boolean {
    let isTainted = false;
    if (form && form.attributeGroups) {
      for (const group of form.attributeGroups) {
        if (group.attributes) {
          for (const attribute of group.attributes) {
            if (taintedValues[attribute.alias] === true) {
              isTainted = true;
              break;
            }
          }
        }
      }
    }

    return isTainted;
  }

  /**
   * @inheritdoc
   */
  async buildPosterDto(
    poster: CartPoster,
    formatId: string,
    status: string,
    currentLanguage: string
  ): Promise<PosterLog.CreationLog> {
    // Create signage object

    const typeData = await getPosterService<ITemplatesService>('templates').loadTypeData(
      { itemId: poster.type.itemId },
      null
    );

    const template = typeData.templates.get(formatId);
    if (!template) {
      throw new Error(`Did not find template for cart poster's format ${formatId}`);
    }

    const signage: PosterLog.CreationLog = {
      status: status != null ? status : Statutes.TO_BE_PRINTED,
      templateId: template.templateId,
      copies: poster.formatsCopiesMap[formatId].copies,
      comment: poster.comment,
      deadlineDate: poster.deadlineDate,
      isTainted: this.isSignageTainted(typeData.form, poster.taintedValues),
      values: [],
      source: DEFAULT_SIGNAGE_SOURCE,
      label: null,
    };

    // Update label (if key defined for the type)
    signage.label = typeData.valuesKeys.includes(AttributeAlias.DESIGNATION)
      ? ({ [currentLanguage]: poster.values[AttributeAlias.DESIGNATION] } as unknown as Label)
      : null;

    // Update signage values (only values defined for the form type)
    for (const key in poster.values) {
      if (Object.hasOwnProperty.call(poster.values, key) && typeData.valuesKeys.includes(key)) {
        signage.values.push({ attributeAlias: key, value: poster.values[key] });
      }
    }

    return signage;
  }

  /**
   * @inheritdoc
   */
  async renderPosters(posterIds: string[]): Promise<string> {
    return await logs.renderPosters(posterIds);
  }

  /**
   * @inheritdoc
   */
  public buildnewSignageItem(
    signageId: number,
    familyId: string,
    initialValues: SignageValues | null
  ): CartPoster {
    return {
      id: signageId,
      familyId: familyId,
      formatsCopiesMap: {},
      // Clone initial values so values will be unique to poster item
      values:
        initialValues != null ? (JSON.parse(JSON.stringify(initialValues)) as SignageValues) : {},
      taintedValues: {},
      deadlineDate: Vue.filter('isoDateFull')(new Date()) as string,
      comment: null,
      attributesSummary: [],
      areAttributesValid: true,
      isFormatsCopiesValid: true,
      isValid: true,
      nbDemands: 0,
      unread: true,
    } as unknown as CartPoster;
  }

  /**
   * Initializes all defaults values, the formatCopies object,
   * resets the formatId if invalid, updates the inputs summary
   *
   * @param signageItem - Signage item to update
   * @param typeData - Type object with data (form etc.)
   * @param setValueFunction - Set signage item value function
   */
  updateSignageItemForType(
    signageItem: {
      values: Record<string, AttributeValues.AttributeValue>;
      taintedValues: { [alias: string]: boolean };
      type: TemplateTypes.RootType | TemplateTypes.Type;
      formatId: string | undefined | null;
      formatsCopiesMap: FormatsCopiesMap;
      attributesSummary: AttributeSummary[];
    },
    typeData: TypeData,
    setValueFunction?: (
      signageItem: any,
      alias: string,
      value: any,
      attribute: Form.Attribute | null
    ) => void
  ) {
    // Init type
    signageItem.type = typeData.type;

    // Init values with default values
    for (const alias in typeData.defaultValues) {
      if (
        setValueFunction != null &&
        Object.hasOwnProperty.call(typeData.defaultValues, alias) &&
        typeof signageItem.values[alias] === 'undefined'
      ) {
        // Clone the value, so different posters won't reference the same
        // value
        const defaultValue = JSON.parse(JSON.stringify(typeData.defaultValues[alias]));

        const attribute = typeData.attributeMap.get(alias) ?? null;

        setValueFunction(signageItem, alias, defaultValue, attribute);
      }
    }

    // Init formats copies
    this.initFormatsCopies(signageItem, typeData.formatGroups);

    // Init default format (keep old format)
    const defaultFormat = getPosterService<ITemplatesService>(
      'templates'
    ).getDefaultTemplateTypeFormat(typeData.formatGroups, signageItem.formatId ?? null);
    signageItem.formatId = defaultFormat?.itemId;

    // Init inputs summary
    signageItem.attributesSummary = this.getAttributesSummaryFromValues(signageItem.values, {
      form: typeData.form,
    });
  }

  /**
   * @inheritdoc
   */
  public async buildCartPoster(familyId: string, typeId: string): Promise<CartPoster> {
    const [type, typeFormData, typeFormatGroups] = await Promise.all([
      getPosterService<ITemplatesService>('templates').loadType(typeId),
      getPosterService<ITemplatesService>('templates').loadTypeFormData(typeId),
      getPosterService<ITemplatesService>('templates').loadTypeFormatGroups(typeId, null),
    ]);

    const initialValues = this.getFormInitialValues([
      ...typeFormData.form.inputGroups,
      ...typeFormData.form.attributeGroups,
    ]);

    const poster = this.buildnewSignageItem(this.getNewSignageId(), familyId, initialValues);
    poster.type = type;

    // Init values with default values
    for (const alias in typeFormData.defaultValues) {
      if (
        Object.hasOwnProperty.call(typeFormData.defaultValues, alias) &&
        typeof poster.values[alias] === 'undefined'
      ) {
        // Clone the value, so different posters won't reference the same
        // value
        const defaultValue = JSON.parse(JSON.stringify(typeFormData.defaultValues[alias]));

        const attribute = typeFormData.attributeMap.get(alias) ?? null;

        this.setPosterTaintedValue(poster, alias, false);
        this.setPosterValue(
          poster,
          alias,
          defaultValue,
          attribute,
          {
            attributeMap: typeFormData.attributeMap,
            valuesTypes: typeFormData.valuesTypes,
          },
          'replace'
        );
      }
    }

    // Init formats copies
    this.initFormatsCopies(poster, typeFormatGroups);

    // Init default format (keep old format)
    const defaultFormat = getPosterService<ITemplatesService>(
      'templates'
    ).getDefaultTemplateTypeFormat(typeFormatGroups, null);
    (poster as any).formatId = defaultFormat?.itemId;

    // Init inputs summary
    poster.attributesSummary = this.getAttributesSummaryFromValues(poster.values, {
      form: typeFormData.form,
    });

    return poster as unknown as CartPoster;
  }

  /**
   * Gets the format in order:
   * - the current format if enabled, visible and has copies
   * - the first format that is enabled and visible and has copies
   * - the first format that is enabled and visible
   * - the current format
   *
   * @param poster - creating poster object
   * @returns the format id
   */
  public autoSelectFormat(poster: CartPoster): string {
    if (!poster.formatsCopiesMap) {
      return poster.formatId;
    }

    if (
      poster.formatId &&
      poster.formatsCopiesMap[poster.formatId] &&
      poster.formatsCopiesMap[poster.formatId].visible &&
      !poster.formatsCopiesMap[poster.formatId].disabled &&
      poster.formatsCopiesMap[poster.formatId].copies > 0
    ) {
      // The current format still has copies, do not change it
      return poster.formatId;
    }

    const selectableFormats = Object.keys(poster.formatsCopiesMap).filter(
      (formatId) =>
        poster.formatsCopiesMap[formatId].visible &&
        !poster.formatsCopiesMap[formatId].disabled &&
        services
          .getService<IAuthenticationService>('auth')
          .hasPermission(poster.formatsCopiesMap[formatId].permission)
    );

    if (selectableFormats.length) {
      const firstFormatWithCopies = selectableFormats.find(
        (formatId) => poster.formatsCopiesMap[formatId].copies > 0
      );
      if (firstFormatWithCopies) {
        return firstFormatWithCopies;
      } else {
        return selectableFormats[0];
      }
    }

    return poster.formatId;
  }

  /**
   *
   * @param values - poster values
   * @param typeData - contains the form associated to the poster. Used to build the summary
   * @returns array of inputs summary generated from the poster values
   */
  getAttributesSummaryFromValues(
    values: Record<string, AttributeValues.AttributeValue>,
    typeData: Pick<TypeData, 'form'>
  ): AttributeSummary[] {
    const attributesSummary: AttributeSummary[] = [];

    iterateForm(typeData.form, (attribute) => {
      if (
        attribute.options?.visible &&
        services
          .getService<IAuthenticationService>('auth')
          ?.hasPermission(attribute.permission as string) &&
        attribute.tags?.includes(AttributeCartSummaryTag)
      ) {
        attributesSummary.push({
          alias: attribute.alias,
          label: Vue.filter('piivoTranslate')(attribute),
          value: this.getAttributeSummaryValue(values[attribute.alias], attribute),
        });
      }
    });

    return attributesSummary;
  }

  /**
   * Retrieves the human friendly value of an input summary from the poster values
   *
   * @param attrValue - poster attribute value
   * @param attribute - the attribute
   * @returns the string representation of the value
   */
  getAttributeSummaryValue(
    attrValue: AttributeValues.AttributeValue,
    attribute: Form.Attribute | undefined
  ): string {
    function ignoreValue(value: any) {
      return value === null || value === undefined || value === '';
    }

    if (!attribute || ignoreValue(attrValue)) {
      return '';
    }

    function formatArray(arr: unknown[]) {
      const mappedValues = arr
        .filter((value) => !ignoreValue(value))
        .map((value) => {
          if (typeof value === 'object' && !!value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return Vue.filter('piivoTranslate')(value);
          }
          return value;
        });

      return mappedValues.join(SUMMARY_SEPARATOR);
    }

    if (AttributeTypes.LINKS && DisplayMode.Table === attribute.options.displayMode) {
      // For attribute table, only display value belonging to visible columns
      // Note that the .visible property is already computed with the column's permission
      let lineValues = (attrValue as AttributeValues.TableValue)
        .map((lineValue) => {
          const filteredColValues = lineValue.filter((_, colIdx) => {
            const colDef = attribute.options.displayOptions?.tableOptions?.columns[colIdx];

            return colDef?.visible && colDef?.tags?.includes(AttributeCartSummaryTag);
          });

          return formatArray(filteredColValues);
        })
        .filter((lineValue) => !ignoreValue(lineValue));

      // If there is at least one table line, add an empty string as first element to push
      // the actual first table line to the next line in the markup
      lineValues = lineValues.length > 0 ? ['', ...lineValues] : lineValues;

      return lineValues.join('\n');
    }

    if (Array.isArray(attrValue)) {
      return formatArray(attrValue);
    }

    return attrValue?.toString() ?? '';
  }

  /**
   * Update poster inputs summary.
   * @param poster - The poster to update
   * @param typeData - the type data
   */
  updateInputsSummary(
    poster: {
      values: Record<string, AttributeValues.AttributeValue>;
      attributesSummary: AttributeSummary[];
    },
    typeData: Pick<TypeFormData, 'attributeMap'>
  ): void {
    if (!poster.attributesSummary) {
      // If we receive old posters without attributes summary, do nothing
      poster.attributesSummary = [];
      return;
    }

    // Update each input summary with poster item value
    for (const attributeSummary of poster.attributesSummary) {
      attributeSummary.value = this.getAttributeSummaryValue(
        poster.values[attributeSummary.alias],
        typeData.attributeMap.get(attributeSummary.alias)
      );
    }
  }

  /**
   * Initialize the signage item formats copies Object.
   *
   * @param signageItem - The signage item to initialize
   * @param formatGroups - the poster's type's format groups. Defines the formats
   * to reset to
   */
  initFormatsCopies(
    signageItem: { formatsCopiesMap: FormatsCopiesMap },
    formatGroups: TemplateFormats.FormatGroups[]
  ): void {
    const newformatsCopiesMap: FormatsCopiesMap = {};
    for (const formatGroup of formatGroups) {
      for (const format of formatGroup.formats) {
        const oldFormat = signageItem.formatsCopiesMap[format.itemId] as FormatDetails | undefined;
        newformatsCopiesMap[format.itemId] = {
          label: { ...format.label },
          copies: signageItem.formatsCopiesMap[format.itemId]?.copies || 0,
          visible: oldFormat?.visible ?? true,
          disabled: oldFormat?.disabled ?? false,
          permission: oldFormat?.permission,
        };
      }
    }

    signageItem.formatsCopiesMap = newformatsCopiesMap;
  }

  /**
   * Check if formats copies object contains a valid number of copies for
   * the given format
   *
   * @param formatsCopiesMap - formats copies object
   * @param formatId - Format Id (Guid)
   * @returns the format copies validity
   */
  checkFormatCopies(
    formatsCopiesMap: Record<string, { copies?: number | string }>,
    formatId: string
  ): boolean {
    if (formatsCopiesMap == null) {
      return false;
    }

    const currentFormatCopies = formatsCopiesMap[formatId];

    if (
      !Object.hasOwnProperty.call(formatsCopiesMap, formatId) ||
      !currentFormatCopies ||
      !currentFormatCopies.copies
    ) {
      return false;
    }

    const numCopies = Number(currentFormatCopies.copies);
    return Number.isInteger(numCopies) && numCopies > 0;
  }

  /**
   * Creates a new formatsCopiesMap from the format info
   *
   * @param formatGroups - the groups of expected formats
   * @param formats - the format info map
   */
  formatInfoToCopiesMap(
    formatGroups: TemplateFormats.FormatGroups[],
    formats: { [formatAlias: string]: TemplateValues.FormatInfo }
  ): FormatsCopiesMap {
    const newformatsCopiesMap: FormatsCopiesMap = {};

    for (const formatGroup of formatGroups) {
      for (const format of formatGroup.formats) {
        const info = formats[format.alias];
        newformatsCopiesMap[format.itemId] = {
          label: format.label,
          copies: info?.copies ?? 0,
          visible: info?.visible ?? true,
          disabled: info?.disabled ?? false,
          permission: format.permission ?? undefined,
        };
      }
    }

    return newformatsCopiesMap;
  }

  /**
   * @returns the last used signage id
   */
  getCurrentSignageIdCounter(): number {
    return signageId;
  }

  /**
   * @returns a unique id for a signage in the cart
   */
  getNewSignageId(): number {
    return ++signageId;
  }

  /**
   * @param id - the id to rebase the counter upon
   * Will be ignored if the current counter is already bigger
   */
  rebaseSignageIdCounter(id: number): void {
    if (id > signageId) {
      signageId = id;
    }
  }

  /**
   * Reactively merges the values into the poster's values
   *
   * @param poster - The poster on which to set the value
   * @param values - The values to merge
   * @param typeData - corresponding typeData object of the poster
   */
  mergePosterValues(
    poster: Pick<CartPoster, 'values'>,
    values: { [aliasOrItemId: string]: unknown },
    typeData: TypeData | null
  ): void {
    for (const alias in values) {
      const value = values[alias];
      this.setPosterValue(poster, alias, value, null, typeData, 'replace');
    }
  }

  /**
   * Reactively sets a value on the poster
   *
   * @param poster - The poster on which to set the value
   * @param alias - Object key (property name)
   * @param value - New value
   * @param attribute - the attribute for the alias
   * @param typeData - corresponding typeData object of the poster
   */
  setPosterValue(
    poster: Pick<CartPoster, 'values'>,
    alias: string,
    value: unknown,
    attribute: Form.Attribute | null,
    typeData: Pick<TypeFormData, 'attributeMap' | 'valuesTypes'> | null,
    updateMode: SearchAttributeMapping['updateMode']
  ): void {
    const currentValue = poster.values[alias];
    const nextNormalizedValue = getNormalizedValue(
      value,
      (attribute || (typeData ? typeData.attributeMap.get(alias) : null)) ?? null,
      typeData ? typeData.valuesTypes[alias] : null
    );

    switch (updateMode) {
      case 'replace': {
        this.replacePosterValue(
          poster,
          alias,
          nextNormalizedValue as AttributeValues.AttributeValue
        );
        break;
      }
      case 'prepend': {
        Vue.set(poster.values, alias, [
          ...nextNormalizedValue,
          ...((currentValue ?? []) as unknown[]),
        ]);
        break;
      }
      case 'append': {
        Vue.set(poster.values, alias, [
          ...((currentValue ?? []) as unknown[]),
          ...nextNormalizedValue,
        ]);
        break;
      }
      default: {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            `setPosterValue(): unknown updateMode '${updateMode as unknown as string}'`
          );
        }
        break;
      }
    }
  }

  /**
   * @inheritdoc
   */
  public replacePosterValue(
    poster: Pick<CartPoster, 'values'>,
    alias: string,
    value: AttributeValues.AttributeValue
  ): void {
    Vue.set(poster.values, alias, value);
  }

  /**
   * Applies the attribute mappings of the search mode to the search results
   * to create the new values object which to apply to the form
   *
   * @param searchResultItems - the search result items to apply to the form
   * @param searchMode - the search mode that provided the results
   * @param multipleResults if multiple results should be used, or only the first result
   * @returns a values object with the new attribute values to apply
   */
  mapSearchResultsToValues(
    searchResultItems: { [alias: string]: AttributeValues.AttributeValue }[],
    searchMode: SearchModeSearchConfiguration,
    multipleResults: boolean
  ): SearchResultUpdate {
    const onAttributeResult = searchMode.onAttributeResult;
    if (!onAttributeResult) {
      return {};
    }

    // Parse the mappings from JSON strings, to simple strings/string arrays
    const parsedMappings = Object.fromEntries(
      Object.entries(onAttributeResult.attributeMappings).map(([alias, mapping]) => {
        const parsedMapping = {
          // If 'select' is an array, parse it from json
          select: /\[.+\]/.test(mapping.select)
            ? (JSON.parse(mapping.select) as string[])
            : mapping.select,
          output: mapping.output,
          updateMode: mapping.updateMode,
        };
        return [alias, parsedMapping];
      })
    );

    // Use all results or only the first result
    const normalizedResult = multipleResults ? searchResultItems : searchResultItems.slice(0, 1);
    // Create the values object that will contain the mapped attribute values
    const mappedAttributeValues: {
      [alias: string]: {
        value: AttributeValues.AttributeValue;
        output: string;
        updateMode: SearchAttributeMapping['updateMode'];
      };
    } = {};

    // Step 1: select values per attribute via "select"
    normalizedResult.forEach((resultLineObj) => {
      const selectInResultLine = (expression: string) => {
        // Handle eval in a future version
        if (expression === '') {
          return null;
        }
        return resultLineObj[expression];
      };

      Object.entries(parsedMappings).forEach(([alias, mapping]) => {
        let mappedLineValue: AttributeValues.AttributeValue | null = null;

        if (Array.isArray(mapping.select)) {
          // Array: the mapped line value is the array of evaluated select expressions
          // in the same order
          mappedLineValue = mapping.select.map((selectCol) =>
            selectInResultLine(selectCol)
          ) as AttributeValues.AttributeValue;
          // Cast as a simple attribute value, since an array of attribute values
          // is itself a simple attribute value: links combo, links grid...
        } else {
          // Else the mapped line value is simply the evaluated select expression
          mappedLineValue = selectInResultLine(mapping.select);
        }

        if (multipleResults) {
          // If multiple lines (search results) should be used, each mapped attribute value
          // is simply the array of all the mapped line values

          mappedAttributeValues[alias] = mappedAttributeValues[alias] ?? {
            value: [],
            output: mapping.output,
            updateMode: mapping.updateMode ?? 'replace',
          };
          // Add the current mapped line value to the final attribute value
          (mappedAttributeValues[alias].value as Array<unknown>).push(mappedLineValue);
        } else {
          // Else each mapped attribute value is the first mapped line value
          mappedAttributeValues[alias] = {
            value: mappedLineValue,
            output: mapping.output,
            updateMode: mapping.updateMode ?? 'replace',
          };
        }
      });
    });

    // Step 2: format values per attribute via "output"
    // handle formatters in mapping.output in a future version
    Object.values(mappedAttributeValues).forEach((update) => {
      if (update.output === 'TABLE') {
        // Ensure value is 2d array
        let value = update.value as unknown;
        if (!Array.isArray(value)) {
          value = [value];
        }
        if (!Array.isArray((value as Array<unknown>)[0])) {
          value = [value];
        }
        update.value = value as AttributeValues.AttributeValue;
      }
    });

    return mappedAttributeValues;
  }

  /**
   * Reactively merges the tainted values object flags
   * into the poster's tainted values object
   *
   * @param poster - The poster on which to set the values
   * @param taintedValues - The values to merge
   */
  mergePosterTaintedValues(
    poster: { taintedValues: { [aliasOrItemId: string]: boolean } },
    taintedValues: { [aliasOrItemId: string]: boolean }
  ): void {
    for (const alias in taintedValues) {
      this.setPosterTaintedValue(poster, alias, taintedValues[alias]);
    }
  }

  /**
   * Reactively sets a tainted value flag on the poster
   *
   * @param poster - The poster on which to set the value
   * @param alias - Object key (property name)
   * @param tainted - New value of the tainted flag
   */
  setPosterTaintedValue(
    poster: { taintedValues: { [aliasOrItemId: string]: boolean } },
    alias: string,
    tainted: boolean
  ): void {
    Vue.set(poster.taintedValues, alias, tainted);
  }

  /**
   * Clears cache
   */
  private clearCache(): void {
    getExtensionPoint<PosterEngineExtensionPoint>('poster.engine').clearCachedData();
    this.store.commit(`${POSTER_NAMESPACE}/${RESET_STATE}`);
  }

  /**
   * @inheritdoc
   */
  public checkCartPoster(poster: CartPoster | null | undefined): void {
    if (poster == null) {
      return;
    }

    // Check if at least one of the formats copies number is filled
    const isCopiesValid = Object.keys(poster.formatsCopiesMap || {}).some((formatId) =>
      this.checkFormatCopies(poster.formatsCopiesMap, formatId)
    );

    let areAttributesValid = true;

    const typeData =
      poster.type != null
        ? getPosterService<ITemplatesService>('templates').getTypeData(poster.type.itemId, null)
        : null;
    if (typeData) {
      const form = typeData.form;

      const checkResult = areAllAttributesValid(poster.values, form, {
        validateInvisibleItems: false,
        breakOnFirstError: true,
      });
      const { errors } = checkResult;
      areAttributesValid = Object.keys(errors).length === 0;
    }

    poster.areAttributesValid = areAttributesValid;
    poster.isFormatsCopiesValid = isCopiesValid;
    poster.isValid = isCopiesValid && areAttributesValid;
  }

  /**
   * @inheritdoc
   */
  public getFormInitialValues(
    attributeGroups: Pick<Form.AttributeGroup, 'attributes'>[]
  ): SignageValues {
    // 1. user extended properties
    const values = services.getService<IFormsService>('forms').getDefaultValues(attributeGroups);

    // 2. url values
    const urlValues = queryToFormValues(router.currentRoute.query, attributeGroups);
    Object.assign(values, urlValues);

    // 3. global values
    const globalValues =
      getPosterService<IGlobalAttributesService>('globalAttributes').getGlobalValues();
    Object.assign(values, globalValues);

    return values;
  }
}
